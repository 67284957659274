<template>
  <div
    class="listings-container mx-3 listings-container-placeholder row fs-listings-res grid-layout"
  >
    <div class="listing-item item-placeholder" v-for="i in count" :key="i">
      <content-placeholders :rounded="true">
        <content-placeholders-img />
        <!-- <content-placeholders-heading/> -->
        <content-placeholders-text :lines="lines" />
      </content-placeholders>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LoaderListItem',
  props: {
    count: {
      type: Number,
      default: 4
    },
    lines: {
      type: Number,
      default: 3
    }
  }
}
</script>

