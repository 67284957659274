<template>
  <div v-if="Object.keys(details).length">
    <div class="loading-wrap">
      <div class="inner-wrap" :class="{ isActive: loading }">
        <circle2></circle2>
      </div>
    </div>
    <!-- Header -->
    <div
      class="fs-container"
      :class="{
        'full-map': fullMap,
        'is-map-view': isMapView,
        'is-properties-view': !isMapView
      }"
    >
      <!-- map listing-->
      <div id="map-column" class="fs-inner-container map-column">
        <div class="fullwidth-loading-wrap" v-if="fullMap && spinnerLoading">
          <b-spinner label="Spinning" variant="primary"></b-spinner>
        </div>
        <!-- Map -->
        <div id="map-container">
          <div class="backbtns-wrap">
            <b-button @click="backBtn" id="back-btn" v-if="showBackLink">
              <span class="hi-arrow-right icon ml-2"></span>
              <span>عودة</span>
            </b-button>
          </div>
          <!--                    star mapping-->
          <div id="map">
            <gmap-map
              ref="mapRef"
              :center="mapCenter"
              :zoom="mapZoom"
              :options="options"
              style="width: 100%; height: 100%"
              @tilesloaded="onTilesLoaded"
              @zoom_changed="onZoomChanged"
              @dragend="onDraged"
            >
              <gmap-info-window
                :options="infoWindowOptions"
                :opened="infoWinOpen"
                :position="infoWindow.position"
                @closeclick="closeInfoWinOpen"
                :class="{
                  'is-active': propId == infoWindow.place_id
                }"
              >
                <router-link
                  :to="{
                    name: 'agent-properties',
                    params: { id: infoWindow.id }
                  }"
                >
                  <div class="p-3 d-flex justify-content-between">
                    <div class="">
                      <h5 class="mb-3">{{ infoWindow.name }}</h5>
                      <h6>
                        <img
                          src="@/assets/images/icons/map.png"
                          alt="Map Icon"
                          loading="lazy"
                        />
                        {{ infoWindow.add }}
                      </h6>
                      <h6 class="font-14 mt-4">
                        مناطق الخدمة
                        <span class="font-14-black mr-2">
                          {{ infoWindow.vicinity }}
                        </span>
                      </h6>
                    </div>
                  </div>
                </router-link>
              </gmap-info-window>

              <gmap-custom-marker
                v-for="(property, index) in agents"
                :key="`custom-marker-${index}`"
                :ref="`marker-${
                  property.place_id != null
                    ? property.place_id
                    : property.user_id
                }`"
                :id="`marker-${
                  property.place_id != null
                    ? property.place_id
                    : property.user_id
                }`"
                class="map-marker"
                :class="{
                  'is-active':
                    propId ==
                      (property.place_id != null
                        ? property.place_id
                        : property.user_id) && infoWinOpen == true,
                  'is-current':
                    (property.place_id != null
                      ? property.place_id
                      : property.user_id) == currentPropertyId
                }"
                :marker="{
                  lat: property.geometry.location.lat,
                  lng: property.geometry.location.lng
                }"
              >
                <router-link
                  @mouseover.native="
                    toggleInfoWindow(
                      property,
                      property.place_id !== null
                        ? property.place_id
                        : property.user_id
                    )
                  "
                  @mouseout.native="closeInfoWinOpen"
                  :to="{
                    name: 'agent-properties',
                    params: {
                      id:
                        property.place_id != null
                          ? property.place_id
                          : property.user_id
                    }
                  }"
                  ref="noopener"
                >
                  <div
                    v-if="currentZoom > 12"
                    class="map-marker-price"
                    :class="{
                      'is-visited': isInVisitedProps(
                        property.place_id !== null
                          ? property.place_id
                          : property.user_id
                      )
                    }"
                  >
                    <div class="p-1">
                      <p class="text-white mb-0 font-9">{{ property.name }}</p>
                    </div>
                  </div>
                  <div
                    v-else
                    class="dot-map-marker"
                    :class="{
                      'is-visited': isInVisitedProps(
                        property.place_id != null
                          ? property.place_id
                          : property.user_id
                      )
                    }"
                  ></div>
                </router-link>
              </gmap-custom-marker>

              <!-- Current Place Marker -->
              <gmap-custom-marker
                ref="current-property-marker"
                id="current-property-marker"
                class="map-marker is-current"
                :marker="mapCenter"
              >
                <span class="dot-map-marker"> </span>
              </gmap-custom-marker>
            </gmap-map>
          </div>
          <!-- Fullwidth toggle btn -->
          <button
            class="custom-map-button"
            id="fullwidth-btn"
            variant="light"
            :class="{ 'is-active': fullMap }"
            @click="fullMap = !fullMap"
          >
            <span class="hi-expand"></span>
          </button>

          <!-- Current Locatino btn -->
          <button
            class="custom-map-button"
            id="gps-btn"
            variant="light"
            @click="geolocate()"
          >
            <span class="hi-gps-fixed"></span>
          </button>
        </div>
      </div>
      <!--agent Detail-->
      <div
        class="fs-inner-container properties-column"
        :class="{ 'full-width-mages': fullWidthImages }"
      >
        <div class="fs-content" id="property-info-area">
          <!-- Loading Placeholder -->
          <div v-if="loading" class="preview-area-wrap loadding-area">
            <div class="preview-area">
              <div id="previewSlider">
                <content-placeholders>
                  <content-placeholders-img />
                  <!-- <content-placeholders-heading/> -->
                  <!-- <content-placeholders-text :lines="3" /> -->
                </content-placeholders>
              </div>
            </div>
          </div>
          <!--agent-listing-details-->
          <div v-else>
            <!--agent-listing-details-->
            <div class="card-1 my-2 mx-2">
              <div
                id="agent-listing-details"
                class="d-flex justify-content-between"
              >
                <div class="p-24">
                  <h5 class="mb-md-3" :class="{ 'mb-1': mq === 'xs' }">
                    {{ details.name }}
                  </h5>
                  <span
                    role="button"
                    @click="openHectarPlusModal"
                    :class="['hectar-plus', hectarPlusSubscribedBorder]"
                  >
                    <span :class="['title', hectarPlusSubscribedColor]">
                      {{ hectarPlusSubscribedText }}
                    </span>
                    <img
                      class="mr-1"
                      :src="hectarPlusSubscribedIcon"
                      alt="subscribed"
                      v-if="hectarPlusSubscribed"
                    />
                    <img
                      v-else
                      class="mr-1"
                      :src="hectarPlusUnsubscribedIcon"
                      alt="unsubscribed"
                    />
                  </span>
                  <div class="rating-wrap justify-content-start my-1">
                    <rate
                      :value="Math.round(details.rating)"
                      :readonly="true"
                      id="experience-rating"
                      :length="5"
                    />
                  </div>
                  <h6
                    v-if="!hectarPlusSubscribed"
                    class="font-14 my-md-4"
                    :class="{ 'my-1': mq === 'xs' }"
                  >
                    مناطق الخدمة
                    <span class="font-14-black mr-2">
                      {{ details.vicinity }}
                    </span>
                  </h6>
                  <div class="mt-3 d-flex align-items-center">
                    <span class="list-tags font-12">
                      <a
                        @click.stop="here()"
                        class="text-white"
                        target="_blank"
                        :href="`tel:+${details.international_phone_number}`"
                      >
                        <img
                          src="@/assets/images/icons/call.png"
                          alt="call"
                          loading="lazy"
                        />
                      </a>
                    </span>
                    <span class="list-tags-outline font-12 brand-color">
                      <a
                        @click.stop="here()"
                        target="_blank"
                        :href="`https://api.whatsapp.com/send?phone=${details.international_phone_number}&text=مرحباً ${details.name}`"
                      >
                        <img
                          src="@/assets/images/icons/what.png"
                          alt="what"
                          loading="lazy"
                        />
                      </a>
                    </span>

                    <span class="list-tags-outline font-12 brand-color">
                      <a
                        v-if="Object.keys(details.geometry.location)"
                        @click.stop="here()"
                        target="_blank"
                        :href="`http://www.google.com/maps/place/${details.geometry.location.lat},${details.geometry.location.lng}`"
                      >
                        <img
                          src="@/assets/images/icons/contact.png"
                          alt="loaction"
                          loading="lazy"
                        />
                      </a>
                    </span>
                  </div>
                </div>
                <div class="h-100-24">
                  <div
                    class="side-card companyInfo h-100-24"
                    :class="{ 'side-card-xs': mq === 'xs' }"
                  >
                    <h5 class="font-14">للبيع</h5>
                    <h5>
                      {{ details.forSaleCount || 'لا يوجد'
                      }}<span v-show="details.forSaleCount" class="mr-1"
                        >عقار</span
                      >
                    </h5>
                  </div>
                  <div
                    class="side-card companyInfo h-100-24"
                    :class="{ 'side-card-xs': mq === 'xs' }"
                  >
                    <h5 class="font-14">للايجار</h5>
                    <h5>
                      {{ details.forRentCount || 'لا يوجد'
                      }}<span class="mr-1" v-show="details.forRentCount"
                        >عقار</span
                      >
                    </h5>
                  </div>
                </div>
              </div>
            </div>

            <!-- Loader -->
            <LoaderListItem v-if="placeHolder" />

            <div>
              <!--agent-listing-properties-->
              <section id="agent-listing-properties" class="p-4">
                <div class="row align-items-center m-0">
                  <div class="col-md-7 p-0">
                    <h5>العقارات المتاحه على هكتار لـ{{ details.name }}</h5>
                  </div>
                  <div class="col-md-5 p-0 text-center my-0 my-xs-4">
                    <b-form-group class="m-0">
                      <b-form-radio-group
                        v-model="filter"
                        id="filter-status-group"
                        :options="statuses"
                        value-field="_id"
                        text-field="nameAr"
                        buttons
                        button-variant="light"
                        name="radios-btn-default"
                        class="p-0"
                      ></b-form-radio-group>
                    </b-form-group>
                  </div>
                </div>
              </section>

              <!-- If no any realestates -->
              <div
                v-if="realestates.length === 0"
                class="h-100 text-center p-3 d-flex flex-column justify-content-center align-items-center"
              >
                <span class="hi-city icon font-30"></span>
                <h5 class="my-3">عفواً، لا توجد عقارات ل {{ details.name }}</h5>
                <h5 class="text">حاول البحث في مكتب اخر</h5>
              </div>

              <!-- listing-properties-->
              <section id="listing-properties ">
                <div
                  id="listings-list"
                  class="listings-container grid-layout p-10"
                >
                  <listing-item
                    v-for="property in realestates"
                    :key="property._id"
                    :property="property"
                    :isAgent="true"
                    usedIn="properties"
                    @on-delete-property="deleteThisProperty"
                  >
                  </listing-item>
                </div>
              </section>
            </div>
          </div>

          <div
            v-if="pagination.pagesCount > 1"
            class="fs-listings mb-0 mb-sm-5"
          >
            <!-- Pagination -->
            <div class="pagination-container margin-bottom-45">
              <nav class="pagination">
                <b-pagination
                  :value="pagination.pageNumber"
                  :total-rows="pagination.count"
                  :per-page="pagination.pageSize"
                  align="center"
                  aria-controls="listings-list"
                  @change="paginateRealEstates"
                  hide-goto-end-buttons
                >
                  <template slot="next-text"
                    ><i class="hi-angle-left"></i
                  ></template>
                </b-pagination>
              </nav>
              <p>
                {{ pagiFrom }} - {{ pagiTo }} من {{ pagination.count }} نتيجة
                بحث
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <HectarPlusModal
      v-if="dialogState"
      :dialogState="dialogState"
      @changeDialogState="changeDialogState"
    />
  </div>
</template>

<style>
.btn-light:not(:disabled):not(.disabled):active,
.btn-light:not(:disabled):not(.disabled).active,
.show > .btn-light.dropdown-toggle {
  color: #212529 !important;
  background-color: #dae0e5 !important;
  border-color: #d3d9df !important;
}
</style>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import { userService } from '../../services'
import { realEstateService } from '@/services' //Modals
import store from '@/store'
import { gmapApi } from 'vue2-google-maps'
import GmapCustomMarker from '@/components/map/GmapCustomMarker.vue'
import { EventBus } from '@/services/EventBus'
import config from '@/config'
import moment from 'moment-timezone'
import 'moment/locale/ar'
import { Circle2 } from 'vue-loading-spinner'
import ListingItem from '@/components/property/ListingItem.vue'
import LoaderListItem from '@/components/agents/LoaderListItem'
import '@/styles/property/rating-block.scss'
import '@/styles/property/pagination.scss'

function getBoundsZoomLevel(bounds, mapDim) {
  var WORLD_DIM = { height: 256, width: 256 }
  var ZOOM_MAX = 21

  function latRad(lat) {
    var sin = Math.sin((lat * Math.PI) / 180)
    var radX2 = Math.log((1 + sin) / (1 - sin)) / 2
    return Math.max(Math.min(radX2, Math.PI), -Math.PI) / 2
  }

  function zoom(mapPx, worldPx, fraction) {
    return Math.floor(Math.log(mapPx / worldPx / fraction) / Math.LN2)
  }

  var ne = bounds.getNorthEast()
  var sw = bounds.getSouthWest()

  var latFraction = (latRad(ne.lat()) - latRad(sw.lat())) / Math.PI

  var lngDiff = ne.lng() - sw.lng()
  var lngFraction = (lngDiff < 0 ? lngDiff + 360 : lngDiff) / 360

  var latZoom = zoom(mapDim.height, WORLD_DIM.height, latFraction)
  var lngZoom = zoom(mapDim.width, WORLD_DIM.width, lngFraction)

  return Math.min(latZoom, lngZoom, ZOOM_MAX)
}

export default {
  layout: 'map',
  props: ['closeImages360'],
  components: {
    GmapCustomMarker,
    Circle2,
    ListingItem,
    LoaderListItem,
    HectarPlusModal: () => import('@/components/modals/HectarPlusModal.vue')
  },
  data() {
    return {
      map: null,
      firstLoad: true,
      isFirstTime: true,
      isFirstTilesLoad: true,
      options: {
        scrollwheel: false,
        fullscreenControl: false,
        streetViewControl: false,
        minZoom: 9,
        mapTypeControlOptions: {
          style: 1,
          position: 12
        },
        styles: config.mapStyles
      },
      loading: false,
      currentZoom: null,
      fullMap: false,
      activeFilters: {},
      mapMarkers: [],
      infoWindow: {},
      infoWinOpen: false,
      infoWindowOptions: {
        disableAutoPan: true,
        pixelOffset: {
          width: 0,
          height: -35
        }
      },
      isMapView: false,
      property: {},
      requestService: [],
      isVisible: false,
      // check property meta
      stikyKit: {
        options: {
          parent: '.sidebar-wrapper',
          offset_top: 170
        }
      },
      fontFile: require('@/styles/fonts/tma/TheMixArabic-Plain.ttf'),
      fontFileBold: require('@/styles/fonts/tma/TheMixArabic-Bold.ttf'),
      fullWidthImages: false,
      details: {},
      id: this.$route.params.id,
      propertyStatus: '',
      currentPropertyId: null,
      propId: null,
      agentProperties: [],
      filter: 0,
      placeHolder: null,
      realestates: [],
      messagesIcon: require('../../assets/images/svgs/messages-icon.svg'),
      closeIcon: require('../../assets/images/svgs/close-icon.svg'),
      hectarPlusSubscribedIcon: require('@/assets/images/svgs/checkmark.svg'),
      hectarPlusUnsubscribedIcon: require('@/assets/images/svgs/close-icon.svg'),
      userData: JSON.parse(localStorage.getItem('tokenData')),
      dialogState: false,
      pagination: {
        pageSize: 10,
        pageNumber: 1,
        pagesCount: 0,
        count: 0
      }
    }
  },
  watch: {
    async '$route.params.id'(val) {
      this.PlaceDetails(val)
    },
    filter() {
      this.pagination.pageNumber = 1
      this.getRealEstates(this.filter)
    }
  },
  async created() {
    this.setMapCenterFromUrl()
    this.setMapEventsFlag(false)
    EventBus.$on('placeSelected', (place) => {
      if (this.map && place.types) {
        // Viewport Bounderies
        var viewportBounds = this.map.getBounds(),
          zoom
        if (place.types.includes('country')) {
          // all the country
          zoom = 5
        } else if (place.types.includes('locality')) {
          // city
          zoom = 10
        } else if (place.types.includes('route')) {
          // street
          zoom = 14
        } else if (place.types.includes('point_of_interest')) {
          // spcial place
          zoom = 16
        } else if (
          place.types.includes('neighborhood') ||
          place.types.includes(
            'sublocality_level_1' || place.types.includes('sublocality')
          )
        ) {
          // spcial place
          zoom = 14
        } else {
          var mapConainer = document.getElementById('map-container')
          var w = mapConainer.offsetWidth
          var h = mapConainer.offsetHeight
          zoom = getBoundsZoomLevel(viewportBounds, { width: w, height: h })
        }
        this.map.setZoom(zoom)
      }
    })
    EventBus.$on('on-toggle-infoWindow', (property, id) => {
      this.toggleInfoWindow(property, id)
    })
    EventBus.$on('on-close-infoWindow', () => {
      this.closeInfoWinOpen()
    })
    this.PlaceDetails()
  },
  mounted() {
    this.setSpinnerLoading(false)
    setTimeout(() => {
      this.$refs.mapRef.$mapPromise.then(() => {
        if (this.$route.name == 'agent-properties')
          this.map = this.$refs.mapRef.$mapObject
      })
    }, 700)
    let propId = this.$route.params.id

    // Add Prop to Visited Properties
    this.addtoVisitedProps(propId)

    // open iamges 360
    if (this.$route.params.images360 == 1) this.images360IsActive = true

    // set current marker
    this.$emit('set-current-marker', propId)
  },
  computed: {
    google: gmapApi,
    ...mapState('accountState', ['realEstateStatus']),
    ...mapState('userState', ['spinnerLoading']),
    ...mapState('realEstateState', [
      'pageSize',
      'mapEventsFlag',
      'mapCenter',
      'mapZoom',
      'visitedProps'
    ]),
    ...mapState('accountState', ['status', 'user']),
    ...mapState('agentLocator', ['agents']),
    showBackLink() {
      return this.$route.params.id !== 'default'
    },
    showMyPropertiesBtn() {
      return this.fullMap == true && this.$route.params.id === this.user._id
    },
    showMyFavoritesBtn() {
      return this.fullMap == true && this.$route.params.id == 'my-favorites'
    },
    statuses() {
      let statuses = [{ nameAr: 'الكل', nameEn: 'all', _id: 0 }]
      this.realEstateStatus.forEach((status) => {
        if (['rent', 'sale'].includes(status.nameEn)) {
          statuses.push(status)
        }
      })
      return statuses
    },
    userId() {
      return this.$route.params.id
    },
    isPropertyPage() {
      return this.$route.name == 'property'
    },
    numberOfLikes() {
      return this.property.numberOfLikes + this.likesCount
    },
    isLoggedIn() {
      return this.status.loggedIn
    },
    isOwner() {
      if (this.property && this.property.owner) {
        return this.user && this.user._id == this.property.owner._id
      }
      return false
    },
    payTypeOptions() {
      return config.payTypeOptions
    },
    selectedSides() {
      return config.selectedSides
    },
    images() {
      let images = this.property.images
      if (images.length > 1) {
        let index = this.property.mainImageIndex
        images.unshift(images.splice(index, 1)[0])
      }
      return images
    },
    imagesSmall() {
      let imagesSmall = this.property.imagesSmall
      if (imagesSmall.length > 1) {
        let index = this.property.mainImageIndex
        imagesSmall.unshift(imagesSmall.splice(index, 1)[0])
      }
      return imagesSmall
    },

    mq() {
      return this.$mq
    },
    hectarPlusSubscribed() {
      return this.details.environments
    },
    hectarPlusSubscribedText() {
      if (this.hectarPlusSubscribed) return 'مشترك في هكتار بلس'
      else return 'غير مشترك في هكتار بلس'
    },
    hectarPlusSubscribedColor() {
      if (this.hectarPlusSubscribed) return 'hectar-plus-subscribed-color'
      else return 'hectar-plus-unsubscribed-color'
    },
    hectarPlusSubscribedBorder() {
      if (this.hectarPlusSubscribed) return 'hectar-plus-subscribed-border'
      else return 'hectar-plus-unsubscribed-border'
    },
    pagiFrom() {
      return this.pagination.pageNumber < 2
        ? 1
        : 1 + this.pagination.pageSize * (this.pagination.pageNumber - 1)
    },
    pagiTo() {
      return this.pagination.pageNumber * this.pagination.pageSize >
        this.pagination.count
        ? this.pagination.count
        : this.pagination.pageNumber * this.pagination.pageSize
    }
    // receiver() {
    //   return {
    //     receiver: {
    //       _id: this.details._id,
    //       name: this.details.name,
    //       phoneNumber: this.details.international_phone_number
    //     },
    //     subjectId: this.details._id
    //   }
    // }
  },
  methods: {
    ...mapActions('realEstateState', ['getSingleProperty']),
    ...mapMutations('realEstateState', [
      'addtoVisitedProps',
      'setPropertiesFound',
      'setMapCenter',
      'setMapEventsFlag'
    ]),
    ...mapMutations('userState', ['setSpinnerLoading']),
    ...mapActions('agentLocator', ['getAgents']),
    beforeLeave() {
      this.loading = true
    },
    afterEnter() {
      this.loading = false
    },
    async getRealEstates(filter) {
      try {
        this.placeHolder = true
        const query = `account=${this.userId}&pageSize=${
          this.pagination.pageSize
        }&pageNumber=${this.pagination.pageNumber}${
          filter ? `&status=${filter}` : ''
        }`
        const { data } = await realEstateService.ownerRealEstates(query)
        this.realestates = data.realEstates
        this.pagination.count = data.count
        this.pagination.pagesCount = Math.ceil(
          this.pagination.count / this.pagination.pageSize
        )
      } catch {
        this.$toasted.show('خطأ في تحميل عقارات الجهة العقارية', {
          duration: 2000,
          type: 'error',
          icon: 'fa-close'
        })
      } finally {
        this.placeHolder = false
      }
    },
    async PlaceDetails() {
      const response = await userService
        .getAgentDetails(this.$route.params.id)
        .catch(() => {
          this.$router.push({ name: 404 })
        })
      this.details = response.data.result

      if (this.details.registered) {
        await this.getRealEstates(this.filter)
      }
    },
    deleteThisProperty(propertyId, comesFrom = 'default') {
      this.$dialog
        .confirm('هل أنت متأكد من حذف هذا العقار؟', {
          loader: true,
          okText: 'تأكيد الحذف',
          cancelText: 'إلغاء',
          customClass: 'delete-dialog'
        })
        .then((dialog) => {
          realEstateService.deleteRealEstate(propertyId).then(
            () => {
              dialog.close()
              this.getOwnerProperties()

              this.$toasted.show('تم حذف العقار بنجاح', {
                duration: 2000,
                icon: 'hi-check'
              })

              if (comesFrom == 'single') {
                this.$router.push({
                  name: 'properties',
                  params: { id: this.user._id }
                })
              }
            },
            (error) => {
              dialog.close()
              this.$toasted.show(error, {
                duration: 2000,
                type: 'error',
                icon: 'hi-close'
              })
            }
          )
        })
        .catch(function () {
          console.log('Clicked on cancel')
        })
    },
    toggleInfoWindow: function (property, id) {
      let position = {
        lat: property.geometry.location.lat,
        lng: property.geometry.location.lng
      }
      this.infoWindow.position = position
      this.infoWindow.id = property.user_id
        ? property.user_id
        : property.place_id
      this.infoWindow.name = property.name
      this.infoWindow.vicinity = property.vicinity
      this.convertLatlngToAddress(property.geometry.location)
      // this.infoWindow.formatted_address = property.formatted_address;

      let markerPosition = { position }
      let marker = new window.google.maps.Marker(markerPosition)

      this.infoWindowOptions.pixelOffset = this.getInfowindowOffset(marker)

      //check if its the same marker that was selected if yes toggle
      if (this.propId == id) {
        this.infoWinOpen = !this.infoWinOpen
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true
        this.propId = id
      }
    },

    closeInfoWinOpen() {
      this.infoWinOpen = false
    },

    getInfowindowOffset: function (marker) {
      if (this.map) {
        var center = this.getPixelFromLatLng(this.map.getCenter()),
          point = this.getPixelFromLatLng(marker.getPosition()),
          quadrant = '',
          offset
        quadrant += point.y > center.y ? 'b' : 't'
        quadrant += point.x < center.x ? 'l' : 'r'
        if (quadrant == 'tr') {
          offset = new window.google.maps.Size(-140, 245)
        } else if (quadrant == 'tl') {
          offset = new window.google.maps.Size(140, 245)
        } else if (quadrant == 'br') {
          offset = new window.google.maps.Size(-140, -45)
        } else if (quadrant == 'bl') {
          offset = new window.google.maps.Size(140, -45)
        }

        return offset
      }
    },
    getPixelFromLatLng: function (latLng) {
      var projection = this.map.getProjection()
      var point = projection.fromLatLngToPoint(latLng)
      return point
    },
    async onDraged() {
      this.setSpinnerLoading(true) // Begin Loading
      var bounds = this.map.getBounds()
      this.currentZoom = this.map.getZoom()

      const ne = bounds.getNorthEast()
      const sw = bounds.getSouthWest()

      // 1- Set parmas for generate query
      this.setMapCenterFromMap() // to set lat, lng in url

      // this.setLatLongFromMapOrUrl(ne, sw);

      this.activeFilters.lat1 = sw.lat()
      this.activeFilters.lat2 = ne.lat()
      this.activeFilters.long1 = sw.lng()
      this.activeFilters.long2 = ne.lng()

      // Set lat lng from map center
      let { lat, lng } = this.mapCenter // From state
      this.activeFilters.lat = lat
      this.activeFilters.lng = lng

      // this.activeFilters.pageSize = this.pageSize; // get pageSize From state
      await this.getAgents(this.activeFilters)
      this.convertLatlngToAddress({ lat: Number(lat), lng: Number(lng) })
    },
    onTilesLoaded() {
      if (!this.mapEventsFlag) {
        this.onDraged()
        this.setMapEventsFlag(true)
      }
    },
    onZoomChanged(zoom) {
      if (this.mapEventsFlag) {
        this.currentZoom = zoom
        this.onDraged()
      }
    },
    convertLatlngToAddress(location) {
      // Convert the latlang to formatted address
      if (location) {
        let geocoder = new window.google.maps.Geocoder()
        geocoder.geocode({ latLng: location }, (results) => {
          if (results === null) return
          let placeAddress = results[0].formatted_address
          // var user_city = results[0].address_components.filter(ac=>~ac.types.indexOf('locality'))[0].long_name;
          // console.log(results[0], user_city);
          this.infoWindow.add = placeAddress
        })
      }
    },

    backBtn() {
      // debugger;
      this.$router.go(-1)

      // fix the backBtn issue, if you back to search results page.
      // Still want more clear and maintanable fix :(
      setTimeout(() => {
        if (this.$route.params.id == 'default') {
          this.onDraged()
        }
      }, 100)
    },

    propertyInfo() {
      this.loading = true
      //this.getSingleProperty(this.$route.params.id)
      realEstateService
        .getSingleProperty(this.$route.params.id)
        .then((response) => {
          this.property = response.data

          if (response.data) {
            this.loading = false
            // center map
            var mapCenter = {
              lat: this.property.address.coordinates[0],
              lng: this.property.address.coordinates[1]
            }
            store.commit('realEstateState/setMapCenter', mapCenter)
            // store.commit('realEstateState/setMapZoom', 17);

            // Set Page Title
            let type = this.property.type.nameAr
            let status = this.property.status.nameAr
            let payType =
              this.property.status.nameEn == 'rent' && this.property.payType
                ? '/' + this.payTypeOptions[this.property.payType].text
                : ''
            document.title =
              type +
              ' ' +
              status +
              ' - ' +
              this.numberWithCommas(this.property.price) +
              ' ريال' +
              payType

            // https://medium.com/js-dojo/how-to-add-dynamic-meta-tags-to-your-vue-js-app-for-google-seo-b827e8c84ee9

            // SEO og:img Meta Image
            if (this.images[0]) {
              document.head.querySelector('meta[property="og:image"]').content =
                this.images[0]
              document.head.querySelector('meta[property="og:url"]').content =
                config.BASE_URL + 'property/' + this.property._id + '/0'
              document.head.querySelector(
                'meta[property="og:image:type"]'
              ).content = 'image/jpeg'
            }
            // SEO og:discription Meta Description
            if (this.property.notes) {
              document.head.querySelector(
                'meta[property="og:description"]'
              ).content = this.property.notes
            }

            // Default Texts
            if (this.property.type.nameEn == 'building') {
              this.floorText = 'عدد الأدوار'
              this.unitsText = 'في كل وحدة'
            } else if (this.property.type.nameEn == 'villa') {
              this.floorText = 'عدد الأدوار'
            } else if (this.property.type.nameEn == 'compound') {
              this.floorText = 'عدد الأدوار في كل وحدة'
              this.unitsText = 'في كل وحدة'
            }
          } else {
            //Property not found
            this.$router.push({ name: '404' })
          }
        })
        .catch(() => {
          // this.$toasted.show(error, { duration: 7000, type: 'error', icon: 'hi-close' });
          //this.loading = false;
        })
    },

    moment: function (date) {
      return moment(date)
    },

    slickHandleLazyLoaded(event, slick, image, imageSource) {
      image.attr('src', '')
      image.parent().css('background-image', 'url("' + imageSource + '")')
    },

    setFullWidthImages() {
      this.$emit('set-fullwidth-images', 'toggle')
    },

    hideModal(ref) {
      this.$refs[ref].hide()
    },

    onModalShown() {
      this.$nextTick(() => {
        this.$refs['fullwidthImagesSlider'].reSlick()
        this.$refs['thumbsImagesSlider'].reSlick()
      })
    },

    syncSliderThumbs(vent, slick, currentSlide, nextSlide) {
      this.$refs.thumbsImagesSlider.goTo(nextSlide)
    },

    syncSliderFull(vent, slick, currentSlide, nextSlide) {
      this.$refs.fullwidthImagesSlider.goTo(nextSlide)
    },

    generatePropertyPdf() {
      this.$refs.pdfRef.html2Pdf.generatePdf()
    },
    setMapCenterFromMap() {
      // Set map center in state
      let lat = this.map.center.lat()
      let lng = this.map.center.lng()
      this.setMapCenter({ lat, lng })
    },

    setMapCenterFromUrl() {
      let { lat, lng } = realEstateService.getCurrentUrlParamsObj()
      if (lat && lng) {
        let center = { lat: Number(lat), lng: Number(lng) }
        this.setMapCenter(center)
      }
    },

    geolocate: function () {
      navigator.geolocation.getCurrentPosition((position) => {
        let center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude
        }

        this.setMapCenter(center)
      })
    },

    setCurrentMarker(id) {
      this.currentPropertyId = id
    },

    onOpenimages360() {
      this.images360IsActive = true
    },

    numberFormatter(num, digits) {
      return realEstateService.numberFormatter(num, digits)
    },

    isInVisitedProps(id) {
      return this.visitedProps.includes(id)
    },
    here() {},
    openHectarPlusModal() {
      this.dialogState = true
    },
    changeDialogState(value) {
      this.dialogState = value
    },
    async paginateRealEstates(page) {
      this.pagination.pageNumber = page
      await this.getRealEstates(this.filter)
    }
  } // end of methods
}
</script>

<style lang="scss" scoped>
@import '@/styles/property/map.scss';
@import '@/styles/agents/index';
</style>

<!-- <style>
button.Rate__star {
  border: none !important;
  background: transparent !important;
}
</style> -->
